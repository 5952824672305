import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined,
  WalletOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { signOut } from 'redux/actions/Auth';

// const menuItem = [
// 	  {
// 		title: "Edit Profile",
// 		icon: EditOutlined ,
// 		path: `${APP_PREFIX_PATH}/profile`
//     },
//     {
//     title: "Transaction Details",
//     icon: WalletOutlined,
//     path: `${APP_PREFIX_PATH}/wallet`
//     },
//     {
// 		title: "Account Setting",
// 		icon: SettingOutlined,
// 		path: "/"
//     },
//     {
// 		title: "Billing",
// 		icon: ShopOutlined ,
// 		path: `${APP_PREFIX_PATH}/billing`
// 	},
//     {
// 		title: "Help Center",
// 		icon: QuestionCircleOutlined,
// 		path: "/"
// 	}
// ]

export const NavProfile = ({signOut }) => {
  
  const det= localStorage.getItem('userData')
  var dataa;
  if (localStorage.getItem('userData') != null) {
    dataa = JSON.parse(localStorage.getItem('userData'));
  }
  else {
    dataa = {
      photoURL: '',
      displayName: 'Username'
    } 
  }
  // console.log(dataa.displayName,'name')
  const profileImg =dataa.photoURL ;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <a href={`${APP_PREFIX_PATH}/profile`}><Avatar size={45} src={dataa.photoURL}/></a>
          <div className="pl-3">
            <h4 className="mb-0">{dataa.displayName}</h4>
            <span className="text-muted">Premium member</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item key={1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
