import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Spin } from 'antd';
import Icon from '@ant-design/icons';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, siabullsUserData } from 'configs/AppConfig';
import { db, auth, analytics } from '../../auth/FirebaseAuth';


export const AppViews = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  //getting current user uid
  useEffect(() => {
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        analytics.logEvent('User_logged_in_siabulls', {
          content_type: 'json',
          content_id: new Date(),
          items: user
        });
        console.log('ADMIN USER ID : ', user.uid)
        db.collection('superAdmins').where("uids", "array-contains", user.uid).get()
                .then((snapshot) => {
                  console.log('You are not Admin ? ', snapshot.empty);

                  if (snapshot.empty) {
                    setIsAdmin(true)
                    console.log("I am signing Off ...");

                    try {
                      auth.signOut();
                      localStorage.removeItem('auth_token');
                      localStorage.removeItem('userData');
                      localStorage.removeItem('authbug');
                      localStorage.removeItem('defaultAccount');
                    } catch (err) {
                      console.log('Sign Out Error : ', err);
                    }

                  }
                  else {
                    setIsAdmin(true);
                  }
                })
        
      }
      else {
        console.log('no admin');
        window.location.reload();
      }
    });



  }, [])


  // var userData = JSON.parse(localStorage.getItem('userData'));
  // const [userDataBool, setUserDataBool] = useState(false);
  // // var userDataBool = false;
  // var userInterval = 0;
  // userInterval = setInterval(() => {
  //   userData = JSON.parse(localStorage.getItem('userData'));
  //   if (userData != null) {
  //     clearInterval(userInterval);
  //     console.log('Yay we got the user data')
  //     // userDataBool = true;
  //     setUserDataBool(true);
  //   }
  //   else {
  //     setUserDataBool(false);
  //     console.log('nope not yet')
  //   }
  // }, 1000)

  return (
    <>
      {isAdmin ? 
      
      <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards/new/bot/details`} exact component={lazy(() => import('./bots/AddBot'))} />
        <Route path={`${APP_PREFIX_PATH}/dashboards/:id`} exact component={lazy(() => import('./bots/BotDescription'))} />
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./bots/BotsList`))} />
        <Route path={`${APP_PREFIX_PATH}/project`} component={lazy(() => import(`./project`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />

      </Switch>
    </Suspense>
    :
        <Spin />
    }
    </>

  )
}

export default React.memo(AppViews);