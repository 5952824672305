import { 
  DashboardOutlined,
  ProfileOutlined,
  WechatOutlined,
  LineChartOutlined,
  ShoppingOutlined, 
  FundViewOutlined,
  RedditOutlined,
  ReadOutlined,
  AppstoreOutlined,
  BookOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

// const userData = JSON.parse(localStorage.getItem('userData'));

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard.overview',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'trading-bots',
      path: `${APP_PREFIX_PATH}/bots`,
      title: 'sidenav.dashboard.botTrading',
      icon: RedditOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const projectdNavTree = [{
  key: 'project',
  path: `${APP_PREFIX_PATH}/project`,
  title: 'sidenav.project.overview',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'project-settings',
      path: `${APP_PREFIX_PATH}/project/settings`,
      title: 'sidenav.project.settings',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]


const navigationConfig = [
  ...dashBoardNavTree,
  ...projectdNavTree
]

export default navigationConfig;
